import React from "react";
import SearchBar from "../Components/Searchbar/Searchbar";

import FeatureSection from "./FeatureSection/FeatureSection";
import Footer from "./Footer/Footer";
import HeroSection from "./HeroSection/HeroSection";
import style from "./Home.module.css";
import MenuBar from "./MenuBar/MenuBar";

function Home() {
  
  return (
    <div className={style.root}>
      <MenuBar></MenuBar>
      <div className={style.bodyWrapper}>
        <div className={`${style.mobilesearch}`}>
          <SearchBar />
        </div>
        <HeroSection></HeroSection>

        <FeatureSection></FeatureSection>
      </div>

      <Footer></Footer>
    </div>
  );
}
export default Home;
