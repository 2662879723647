import React from "react";
import style from "./ContactUs.module.css";

import contactUsImage from "../assets/images/contact-us.svg";
import talkIcon from "../assets/logos/talk.png";
import metaIcon from "../assets/images/meta.png";
import instagramIcon from "../assets/images/instagram.png";
import { Link } from "react-router-dom";
import MenuBar from "../Home/MenuBar/MenuBar";
function ContactUs() {
  return (
    <section className={style.root}>
      <MenuBar></MenuBar>
      <div className={style.wrapper}>
        <section className={style.detailsWrapper}>
          <h2 className={style.title}>Contact Us</h2>
          <p className={style.desc}>
            <img src={talkIcon} className={style.icon} alt=''></img> Our team loves to help you.
          </p>
          <article className={style.articleRoot}>
            <h2 className={style.articleHeader}>Email</h2>
            <p className={style.contactMethod}>support@acadque.com</p>
          </article>
          <article className={style.articleRoot}>
            <h2 className={style.articleHeader}>Call</h2>
            <p className={style.contactMethod}>+91 82391 97261</p>
          </article>
          <article className={style.articleRoot}>
            <h2 className={style.articleHeader}>Address</h2>
            <p className={style.contactMethod}>
              Hostel Block, Indian Institute of Technology, Gandhinagar (IIT Gandhinagar), Palaj, Gandhinagar, Gujarat
              (Pincode - 382355).
            </p>
          </article>
          <section className={style.socialMediaContainer}>
            <div className={style.iconwrapper}>
              <a href='https://www.facebook.com/681651799374078/posts/681652809373977/?substory_index=0' target='_blank'>
                <img src={metaIcon} alt='Open Facebook' />
              </a>
              <p>Facebook</p>
            </div>
            <div className={style.iconwrapper}>
              <a href='https://www.instagram.com/acadque/?utm_medium=copy_link' target='_blank'>
                <img src={instagramIcon} alt='Open Facebook' />
              </a>
              <p>Instagram</p>
            </div>
          </section>
        </section>
        <section className={style.imageContainer}>
          <img src={contactUsImage} alt='' />
        </section>
      </div>
    </section>
  );
}
export default ContactUs;
