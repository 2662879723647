import React, { useState } from "react";
import MenuBar from "../../Home/MenuBar/MenuBar";
import ReactQuill,{Quill} from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6
import ImageUploader from "quill-image-uploader";
import style from "./BlogAdd.module.css";

import { uploadImage, uploadNewblog } from "../../Network";
import { useMemo } from "react";
import { UserContextValue } from "../../Context/UserContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";


Quill.register('modules/imageUploader',ImageUploader);


function BlogAdd() {
  const [blogTitle, updateBlogTitle] = useState("");

  const [category, updateCategory] = useState("SSC");
  const [quillContent, updatequillContent] = useState("");
  const [rootUsername] = UserContextValue();
  const quillRef = React.createRef();
  const navigation = useNavigate();

  useEffect(() => {
    if (!rootUsername) {
      navigation("/login");
    }
  }, [rootUsername]);
  const uploadImagetoNetwork = (filetoUpload) => {
    return new Promise((resolve,reject)=>{
      uploadImage(filetoUpload).then(result=>{
        if(typeof result.secure_url === 'string' )
        {
          return resolve(result.secure_url);
        }
      return reject()
      }).catch((err) => {
        return reject()
        
      });
    })
  };

  const modulesMemo = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"],
          [{ color: [] }],
        ],
      
        
      },
      imageUploader:{
        upload:uploadImagetoNetwork
      }
    }),
    []
  );
  const onUploadButton = async () => {
    if (!(blogTitle || category || quillContent)) {
      window.alert("All fields must be.");
    } else {
      const result = await uploadNewblog({
        uploadedBy: rootUsername,
        content: quillContent,
        title: blogTitle,
        category: category,
      }).catch((err) => {
        window.alert("Failed to upload the blog");
      });
      window.alert("Blog uploaded sucessfully");
    }
  };
  return (
    <section className={style.root}>
      <MenuBar></MenuBar>
      <h2 className={style.title}>Create a new Blog</h2>
      <div className={style.blogTitleWrapper}>
        <input
          key='blog_title'
          type='text'
          className={style.blogTitleInput}
          onChange={(e) => {
            updateBlogTitle(e.target.value);
          }}
          value={blogTitle}
          placeholder={"Title"}
        />
        <Button className={style.uploadBtn} name={"Upload"} onClick={onUploadButton} />
      </div>
      <div className={style.blogTitleWrapper}>
        <select
          type='text'
          className={`${style.blogTitleInput} ${style.blogCatInput}`}
          placeholder={"Category"}
          onChange={(e) => {
            updateCategory(e.target.value);
          }}>
          <option value='SSC'>SSC</option>
          <option value='JEE'>JEE</option>
          <option value='BTech'>BTech</option>
          <option value='Medical'>Medical</option>
        </select>
      </div>

      <div className={style.quillWrapper}>
        <ReactQuill
          key='react_quill'
          ref={quillRef}
          theme='snow'
          modules={modulesMemo}
          value={quillContent}
          onChange={(e, edelta, src, editor) => {
            updatequillContent(e);
          }}></ReactQuill>
      </div>
    </section>
  );
}
export default BlogAdd;
