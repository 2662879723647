import React, { useContext } from "react";
import style from "./Question.module.css"
import { Context } from "../Context/Context";
import Questionbox from "./Questionbox";
import MenuBar from "../Home/MenuBar/MenuBar";
import "./Question_Content.css";
import Spinner from "../Components/Spinner/Spinner";
import SearchBar from "../Components/Searchbar/Searchbar";
function Question() {
  const { result,loading } = useContext(Context);
  return (
    <>
      <MenuBar />
      <div className={style.Qmobilesearch}>
        <SearchBar />
      </div>

      <div className='question-container'>
        {loading && <div className="spinner-center"><Spinner small /></div>}
        { !result && <h1 style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Sorry, No Results Found</h1>}
         {
            !loading && result.map((element) => 
            <div className='col md-3' key={element._id}>
              <Questionbox
                id={element._id}
                questionText={element.questionText}
                queImgUrl={element.queImgUrl}
                askedBy={element.askedBy}
                category={element.category}
                paidAmt={`₹${element.paidAmt}`}
                ansBy={element.ansBy}
                t={element.questionTime}
              />
            </div>
        )
         
         }
      </div>
    </>
  );
}
export default Question;
