import React from "react";
import style from "./Footer.module.css";
function Footer() {
  return (
    <footer className={style.root}>
      <div className={style.contactWrapper}></div>
      <div className={style.allLinksContainer}></div>
      <p className={style.copyright}>
        © Copyright 2021, AcadQue. All Rights Reserved.
      </p>
    </footer>
  );
}
export default Footer;
