import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../Components/Button/Button';
import { UserContextValue } from '../../Context/UserContext';
import MenuBar from '../../Home/MenuBar/MenuBar';
import { SPA_PATHS } from '../../SPANavigation';
import style from "./BlogAddAccessChecker.module.css"


/**
 * 
 *  Checks whether the user has signed in or not.If it has then  the user will be redirected to blog create.
 */

function BlogAddAccessChecker()
{
    const [rootUserState,]=UserContextValue(); //Get the logged in user name.
    const nav=useNavigate();

    //Redirect user to the blog screen if they are already logged in.
    useEffect(()=>{
        if(rootUserState) nav(SPA_PATHS.BLOG_CREATE,{ replace:true })
    },[])

    
    return <section className={style.root}>
        <MenuBar/>
       
        <div className={style.wrapper}>
        <h1 className={style.title}>Sign Up / Log In</h1>
        <div className={style.space}></div>
        <Link to={SPA_PATHS.SIGN_UP} className={style.signUpMenu} style={{ color: "white" }}>
        <Button name={"Sign Up"} ></Button>
                </Link>
            <div className={style.space}></div>
            <Link to={SPA_PATHS.LOG_IN}>
                <Button name={"Log In"} bordered></Button>
              </Link>
        </div>
    </section>
}
export default BlogAddAccessChecker;