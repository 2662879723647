import React, { useEffect, useState } from "react";
import style from "./MenuBar.module.css";

import menuIcon from "../../assets/images/menu_black.svg";
import closeIcon from "../../assets/images/close_icon.svg";

import companyLogo from "../../assets/logos/acadque_logo.png";

import { UserContextValue } from "../../Context/UserContext";
import { Link, useLocation, useNavigate } from "react-router-dom";

import SearchBar from "../../Components/Searchbar/Searchbar";
import { SPA_PATHS } from "../../SPANavigation";

function MenuBar() {
  const [isVisisble, updateMenuVisible] = useState(false);
  const [rootUsername, updateRootUsername] = UserContextValue();
  const location = useLocation();
  const navigate = useNavigate();
  const onMenuOpenClick = () => {
    updateMenuVisible(true);
  };
  const onMenuCloseClick = () => {
    updateMenuVisible(false);
  };

  useEffect(() => {
    if (window.matchMedia("(min-width: 1200px)")?.matches) {
      updateMenuVisible(true);
    }
  }, []);

  const onLogoutClick = () => {
    updateRootUsername(null);
    navigate(SPA_PATHS.ROOT, { replace: true });
  };
  return (
    <nav className={style.root}>
      <button className={style.menuButton} onClick={onMenuOpenClick}>
        <img src={menuIcon} alt='Open navigation menu' />
      </button>
      <div className={style.companyLogoTitleWrapper}>
        <Link to='/'>
          <img src={companyLogo} alt='Company Icon'></img>
        </Link>

        <h1>Que</h1>
      </div>

      <nav role='navigation' className={`${style.navigationMenus} ${isVisisble && style.navigationMenuVisible}`}>
        <ul>
          <button className={style.menuCloseIcon} onClick={onMenuCloseClick}>
            <img src={closeIcon} alt='Close menu' />
          </button>
          <a href={"/"}>
            <img src={companyLogo} alt='Company Icon' className={style.resMenuLogo}></img>
          </a>
          {/**Changed between line 58 and 60 */}
          {!location.pathname.match(SPA_PATHS.QUESTION_LIST) && (
            <div className={`${style.mobilesearch}`}>
              <SearchBar />
            </div>
          )}
          <li>
            <Link className='link' to={SPA_PATHS.BLOG_LIST}>
              Blogs
            </Link>
          </li>

          <li>
            <Link className='link' to={SPA_PATHS.ABOUT_US}>
              About Us
            </Link>
          </li>
          <li>
            <Link className='link' to={SPA_PATHS.PRIVACY_POLICY_PAGE}>
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link className='link' to={SPA_PATHS.TERMS_PAGE}>
              Terms of Use
            </Link>
          </li>
          <li>
            <Link className='link' to={SPA_PATHS.CONTACT_US_PAGE}>
              Contact Us
            </Link>
          </li>
          {rootUsername && (
            <button className={style.logOutBtn} onClick={onLogoutClick}>
              Log Out
            </button>
          )}
        
        </ul>
      </nav>
    </nav>
  );
}
export default MenuBar;
//
