import axios from "axios";
const URLS = {
  USER_CREATE: "https://hidden-ridge-05396.herokuapp.com/bloguser",
  USER_LOGIN: "https://hidden-ridge-05396.herokuapp.com/bloguserauth",
  NEW_BLOG: "https://hidden-ridge-05396.herokuapp.com/postblog",
  GET_SINGLE_BLOG: "https://hidden-ridge-05396.herokuapp.com/getblog",
  GET_ALL_BLOGS: "https://hidden-ridge-05396.herokuapp.com/allblogs",
};
const CLOUDINARY_DETAILS = {
  CLOUD_NAME: "dloxx6cbe",
  API_KEY: "213558828714278",
  API_SECRET: "bswmsibJU6i5U0mLTjD6nXbnbV0",
};
const CLOUDINARY_IMAGE_UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUDINARY_DETAILS.CLOUD_NAME}/image/upload`;

function createUser(email, password) {
  return axios.post(URLS.USER_CREATE, {
    email: email || "",
    password: password || "",
  });
}
function logInUser(email, password) {
  return axios.post(URLS.USER_LOGIN, {
    email: email || "",
    password: password || "",
  });
}
async function uploadImage(file) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "image_upload");
  const result = await axios.post(CLOUDINARY_IMAGE_UPLOAD_URL, formData).catch((err) => Promise.reject(err));
  return Promise.resolve(result.data);
}

function uploadNewblog(data) {
  return axios.post(URLS.NEW_BLOG, {
    uploaded_by: data.uploadedBy,
    data: data.content,
    title: data.title,
    category: data.category,
  });
}

function getBlog(id) {
  return axios.get(URLS.GET_SINGLE_BLOG, { params: { id: id } });
}

function getAllBlogs(data) {
  return axios.get(URLS.GET_ALL_BLOGS);
}
export { createUser, uploadImage, logInUser, getBlog, getAllBlogs, uploadNewblog };
