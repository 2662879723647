const SPA_PATHS = {
  ROOT: "/",
  SIGN_UP:"/signup",
  LOG_IN:"/login",
  ABOUT_US: "/aboutus",
  PRIVACY_POLICY_PAGE:"/privacy",
  TERMS_PAGE:"/termsandc",
  CONTACT_US_PAGE:"/contact-us",
  AUTH_VERIFIER:"/auth/verify",

  QUESTION_LIST:"/questions/list",
  QUESTION_VIEW:"/question/view",

  BLOG_CREATE:"/blog/create",
  BLOG_LIST:"/blog/list",
  BLOG_VIEW:"/blog/view",


};

export {SPA_PATHS};