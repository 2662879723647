import React from "react";
import style from "./BlogCard.module.css";
function BlogCard({ title, id, timestamp, category, body, username, onClick }) {
  const isOnClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  };
  return (
    <article className={style.root} key={id} onClick={isOnClick}>
      <h2 className={style.title}>{title}</h2>
      <div className={style.category}>{category && <span className={style.chip}>{category}</span>}</div>
      {username && <p className={style.author}>Written By -&nbsp; {username}</p>}
    </article>
  );
}
export default BlogCard;
