import React from "react";
import Menubar from "../Home/MenuBar/MenuBar";
import "./About.css";


/**
 * 
 * The code consist of the view of the about page.
 */
export default function About() {
  return (
    <div>
      <Menubar />
      <div className="all">
        <article className="allcontent">
          <div className="abouthead">
          <h2 className="abt"> About <span className="q">Que</span> </h2>
          </div>
          <div className="descrip">
            <ul>
              <li>
                With millions of students finding solutions to their questions,
                Here's Que - one stop solution to everything that you have to
                ask.
              </li>
              <li>
                This platform allows students to post their question and pay
                when they get what they are looking for. Keeping in mind the
                pockets of students, we don't impose a subscription plan or
                charge exorbitantly high rates like those of the pre existing
                platforms.
              </li>

              <li>
                We were motivated to provide such platform when pursuing our
                education a time when we were unable to get all our doubts
                solved in classroom and getting a free solution online.We felt
                the need for creating a fair system where one must pay only when
                they feel satisifed of the answer for their question.
              </li>
              <li>
                We barely keep a share of the payment that you make and it
                directly goes to the expert, we don't make you pay if you have
                not got the doubt resolved and lastly, you could search our
                database if someone has already had the same doubt before you.
                So, we envisage serving the students with one platform for all
                their doubts no matter of which field it is - we make our
                sincere efforts to clear it.
              </li>
              <li>
                You might be wondering - what do we have for you. Well, you
                could refer to us with doubts of any difficulty and get it
                resolved on an urgent basis. No fear of being judged for having
                a trivial doubt or knocking the topper's door for not
                understanding a point. We are here for that.
              </li>
            </ul>
          </div>
          <h1 className="textdown">So go on and give us a try!</h1>
        </article>
      </div>
    </div>
  );
}
