import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getBlog } from "../../Network";
import style from "./BlogView.module.css";
/**
 * 
 * Works by fetching the blog id from the url and then fetching the data from blog id and displaying it properly on the screen.
 */

function BlogView() {
  const params = useParams(); 
  const [blogData, updateBlogData] = useState({});

  //Load the blog data 
  useEffect(() => {
    if (params.id) {
      getBlog(params.id)
        .then((result) => {
            updateBlogData(result.data);
        })
        .catch((err) => {
          window.alert("An error occured");
        });
    }
  }, [params]);

  //Format the date properly
  const generateDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toDateString();
  };


  return (
    <section className={style.root}>
      {blogData && (
        <div className={style.wrapper}>
          <h2 className={style.title}>{blogData.title}</h2>
          <p className={style.category}>{blogData.category}</p>
          <p className={style.timestamp}>{generateDate(blogData.timestamp)}</p>
          <p className={style.timestamp}>{`Written By - ${blogData.uploaded_by}`}</p>
          <section className={style.body} dangerouslySetInnerHTML={{ __html: blogData.data }}></section>
        </div>
      )}
    </section>
  );
}
export default BlogView;
