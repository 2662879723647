import React, { useContext} from "react";
import { useParams } from "react-router-dom";
import { Context } from "../Context/Context";
import MenuBar from "../Home/MenuBar/MenuBar";
import playStoreLogo from "../assets/logos/play_store.png";
import appleStoreLogo from "../assets/logos/app_store.png";
import "./Question_Content.css";
function QuestionContent() {
  const { id } = useParams();
  const { show, setShow } = useContext(Context);
  const { result } = useContext(Context);
  return (
    <>
      <MenuBar />
      <div className='movie-container'>
        {result
          .filter((result) => result._id === `${id}`)
          .map((element) => {
            return (
              <div className='item1'>
                <div className='image'>
                  {setShow(true)}
                  <img
                    src={element.queImgUrl ? element.queImgUrl : setShow(false)}
                    style={{ display: show === true ? "" : "none" }}
                    className='card-img-top'
                    alt='...'
                  />
                </div>
                <div className='text'>
                  <h4 className='text-area'>
                    <span className='que1'>Question:</span>
                    {element.questionText}
                  </h4>
                </div>
                <div className='category'>
                  <span>Category: {element.category}</span>
                </div>
            
                <div className='down'>
                  <div className='text-center'>Want to See Answer Download App Now</div>
                  <div className='linkButtonWrapper1'>
                    <a href='http://play.google.com/store/apps/details?id=com.acadque.que' className='storeLinkButton1'>
                      <img src={playStoreLogo} alt='Download the app in Play Store' />
                      <div>
                        <p>
                          Play Store <br /> <span>Avaliable Now</span>
                        </p>
                      </div>
                    </a>
                    <a href='*' className='storeLinkButton1'>
                      <img src={appleStoreLogo} alt='Download the app in Apple App Store' />
                      <div>
                        <p>
                          App Store <br />
                          <span>Releasing Soon</span>
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
export default QuestionContent;
