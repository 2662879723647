import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "./Context/Context";
import { UserCredentialsProvider } from "./Context/UserContext";
import { BlogsHolderProvider } from "./Context/BlogHolderContext";

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <UserCredentialsProvider>
        <BlogsHolderProvider>
          <App />
        </BlogsHolderProvider>
      </UserCredentialsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
