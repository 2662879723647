import React from "react";
import style from "./Button.module.css";
function Button({ className, submitBtn, onClick, bordered, Secondary, maxWidth, wrap, name, children,small }) {
  const isBtnOnClick = (e) => {
    if (typeof onClick == "function") {
      onClick(e);
    }
  };
  const getClassNames = () => {
    var defaultClassName = `${style.button}`;
    if(small) defaultClassName +=` ${style.small}`
    if (bordered) defaultClassName += ` ${style.borderedButton}`;
    if (Secondary) defaultClassName += ` ${style.secondaryBtn} `;
    if (maxWidth) defaultClassName += ` ${style.maxWidth}`;
    if (wrap) defaultClassName += ` ${style.wrap}`;
    if (className) defaultClassName += ` ${className}`;
    return defaultClassName;
  };
  return (
    <React.Fragment>
      {submitBtn ? (
        <input type='Submit' onClick={isBtnOnClick} className={getClassNames()}></input>
      ) : (
        <button onClick={isBtnOnClick} className={getClassNames()}>
          {name || "Button"} {children}
        </button>
      )}
    </React.Fragment>
  );
}
export default Button;
