import React from "react";
import style from "./Spinner.module.css";
function Spinner({ small, fast }) {
  return (
    <div
      className={`${style.loader} ${small && style.smallwidth} ${
        fast && style.fastSpin
      }`}></div>
  );
}
export default Spinner;
