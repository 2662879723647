import React from "react";
import FeatureCard from "./FeatureCard/FeatureCard";
import style from "./FeatureSection.module.css";

import beachImage from "../../assets/images/relax.png";
import powerImage from "../../assets/images/pay-as-go.svg";
import transferImage from "../../assets/images/quick.png";

function FeatureSection() {
  const featuresAndDesc = [
    {
      desc: "We understand that paying to a new platform is something that people refrain from. So, in a bid to develop that trust, we won't make you pay before we deliver. So, on Que you can ask questions without paying anything and pay only when the question is answered.",
      title: "Be relaxed, we won't be asking the payment immediately.",
      image: beachImage,
    },
    {
      desc: "There is a practice of getting customers to subscribe and take payment irrespective of whether or not they are able to get the complete worth of their subscription. So, to tackle that, we don't ask users to subscribe rather, they pay only for the questions that they want to see the answer of.We believe that the customer must not be forced to pay for something which they have not taken.",
      title: "No more subscription headaches.",
      image: powerImage,
    },
    {
      desc: "To deal with the delays in getting answers and the risk of losing payments without getting answers, we have the urgent category where if the question isn't answered in the promised time, we would recredit the balance.",
      title: "Get quick answer's immediately.",
      image: transferImage,
    },
  ];
  return (
    <section className={style.root}>
      <h2 className={style.title}>
        Why should <span>you</span> choose <span>us</span>?
      </h2>
      <div className={style.cardContainers}>
        {featuresAndDesc.map((value, index) => (
          <React.Fragment>
            <div className={style.gap} key={`Gap_div_${index}`}></div>
            <FeatureCard
              customKey={index}
              image={value.image}
              description={value.desc}
              title={value.title}
              inverse={index % 2 === 0 ? false : true}></FeatureCard>
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}
export default FeatureSection;
