import React from "react";
import ReadMoreReact from "read-more-react/dist/components/ReadMoreReact";
import style from "./Questionbox.module.css";
import { Link } from "react-router-dom";
import { SPA_PATHS } from "../SPANavigation";

function Questionbox(props) {
  let { id, questionText, category } = props;

  return (
    <div className={style.item}>
      <Link className={style.link} to={{ pathname: `${SPA_PATHS.QUESTION_VIEW}/${id}` }}>
        <h5 className={style["cardheader"]}>
          <div className={style.head1}>
            <div className={style.que}>
              Question
              <span className={`${style.badge} ${style.bg}`}>{category}</span>
            </div>
            <ReadMoreReact text={questionText} min={0} ideal={250} max={1000}></ReadMoreReact>
          </div>
        </h5>
      </Link>
    </div>
  );
}
export default Questionbox;
