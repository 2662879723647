import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BlogsData } from "../../Context/BlogHolderContext";
import MenuBar from "../../Home/MenuBar/MenuBar";
import { getAllBlogs } from "../../Network";
import BlogCard from "./BlogCard/BlogCard";
import Spinner from "../../Components/Spinner/Spinner";
import style from "./BlogDisplay.module.css";
import Button from "../../Components/Button/Button";
import { SPA_PATHS } from "../../SPANavigation";
/**
 * 
 * Works by fetching the list of blogs and their data directly.
 */

function BlogDisplay() {
  const [storedBlogs, updateStoredBlogs] = BlogsData();
  const [isLoaded, updateisLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (storedBlogs?.length === 0) {
      getAllBlogs()
        .then((result) => {
          updateisLoaded(true);
          updateStoredBlogs(result.data || []);
        })
        .catch((err) => {
                 
        });
    } else {
      updateisLoaded(true);
    }
  }, []);
  const getCardOnClick = (id) => {
    return () => {
      navigate(SPA_PATHS.BLOG_VIEW + "/" + id);
    };
  };
  return (
    <React.Fragment>
      <section className={style.root}>
        <MenuBar></MenuBar>
       <div className={style.titlewrapper}> <h2 className={style.title}>Blogs</h2>
      <Link to={SPA_PATHS.AUTH_VERIFIER}>
      <Button Secondary  small name={"Publish Blog"}></Button>
      </Link>
      
      </div>
        {isLoaded && (
          <section className={style.blogsWrapper}>
            {storedBlogs.map((data) => (
              <BlogCard
                id={data._id}
                category={data.category}
                username={data.uploaded_by}
                title={data.title}
                onClick={data?._id && getCardOnClick(data._id)}></BlogCard>
            ))}
          </section>
        )}
        {!isLoaded && (
          <div className={style.spinwrapper}>
            <Spinner small></Spinner>
          </div>
        )}
      </section>
    </React.Fragment>
  );
}
export default React.memo(BlogDisplay);
