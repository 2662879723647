import React, { useContext } from "react";
import style from "./Searchbar.module.css";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { Context } from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import { SPA_PATHS } from "../../SPANavigation";
const SearchBar = () => {
  const navigate = useNavigate();
  const { question, setquestion } = useContext(Context);
  const { setLoading } = useContext(Context);
  const { setresult } = useContext(Context);

  function handleChange(event) {
    const question = event.target.value;
    setquestion(question);
  }
  function handleSubmit(event) {
    event.preventDefault();
    navigate(SPA_PATHS.QUESTION_LIST);
    setLoading(true)
    trackPromise(
      axios.get("https://hidden-ridge-05396.herokuapp.com/search?searchTerm=" + question).then((data) => {
        setresult(data.data);
        setLoading(false);
      })
    );
  }
  return (
    <div className={`${style.search}`}>
      <form className={`${style.searchform} ${"form"} `} onSubmit={handleSubmit}>
        <input type='text' className={`${style.searchTerm}`} onChange={handleChange} placeholder='Search Question...' />
      </form>
      <button type='submit' className={`${style.searchButton}`} onClick={handleSubmit}>
        <i className='fa fa-search'></i>
      </button>
    </div>
  );
};
export default SearchBar;
