import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";

export const UserCredentialsContext = createContext();
export const UserCredentialsProvider = ({ children }) => {
  const [username, updateUsername] = useState(null);
  return (
    <UserCredentialsContext.Provider value={[username, updateUsername]}>
      {children}
    </UserCredentialsContext.Provider>
  );
};
export const UserContextValue = () => useContext(UserCredentialsContext);
