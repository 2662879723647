import React, { useState } from "react";
import { logInUser } from "../Network";
import style from "./Login.module.css";
import companyLogo from "../assets/logos/acadque_logo.png";
import { useNavigate } from "react-router-dom";
import Spinner from "../Components/Spinner/Spinner";
import { UserContextValue } from "../Context/UserContext";
import { SPA_PATHS } from "../SPANavigation";


function Login() {
  const [email, updateemail] = useState("");
  const [password, updatePassword] = useState("");
  const [error, updateError] = useState("");
  const [showLoader, updateshowLoader] = useState(false);
  const [, updateUserRoot] = UserContextValue();
  const navigation = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    var currentemail = filterText(email);
    var currentPassword = filterText(password);

    if (currentemail.length === 0) {
      updateError("Invalid email. ");
    } else if (currentPassword.length === 0) {
      updateError("Invalid Password.");
    } else {
      updateshowLoader(true);
      logInUser(currentemail, currentPassword)
        .then((result) => {
          updateshowLoader(false);
          if (result.data === "Login Successful") {
            updateUserRoot(currentemail);
            navigation(SPA_PATHS.BLOG_CREATE);
          } else {
            updateError("An errror occured while signing up ");
          }
        })
        .catch((error) => {
          updateshowLoader(false);
          updateError("An error occured while signing up");
        });
    }
  };
  return (
    <div className={style.root}>
      <img src={companyLogo} className={style.logo} alt="" />
      <h2 className={style.title}>Log In</h2>
      <form className={style.signupForm} onSubmit={onSubmit}>
        <div>
          <label htmlFor="userEmail">Email</label>
          <input
            type="email"
            id="userEmail"
            onChange={(e) => {
              updateError("");
              updateemail(e.target.value);
            }}
            value={email}
          />
        </div>
        <div>
          <label htmlFor="userPassword">Password</label>
          <input
            type="password"
            id="userPassword"
            onChange={(e) => {
              updateError("");
              updatePassword(e.target.value);
            }}
            value={password}
          />
        </div>
        <input type="submit" value="Submit" />
      </form>
      <p className={style.error}>{error}</p>
      {showLoader && <Spinner small fast></Spinner>}
    </div>
  );
}
export default Login;
function filterText(text) {
  if (typeof text == "string") {
    text.trim();
  }
  return text;
}
