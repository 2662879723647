import React, { useState } from "react";
import { createUser } from "../Network";
import style from "./SignUp.module.css";
import companyLogo from "../assets/logos/acadque_logo.png";
import { useNavigate } from "react-router-dom";
import { UserContextValue } from "../Context/UserContext";
import Spinner from "../Components/Spinner/Spinner";
import { SPA_PATHS } from "../SPANavigation";

function SignUp() {
  const [email, updateemail] = useState(""); //Stores the state for the email field
  const [password, updatePassword] = useState(""); //Stores the state for the password field
  const [error, updateError] = useState(""); //Stores the error message state
  const [_, updateRootemail] = UserContextValue(); //Stores the credential information about the user.
  const [showLoader, updateshowLoader] = useState(false); //Stores whether to show the loader or when to hide it.
  const navigation = useNavigate();


  const onSubmit = (e) => {
    e.preventDefault(); // Prevent default event handling
    var currentemail = filterText(email);
    var currentPassword = filterText(password);

    if (currentemail.length === 0) 
    {
      updateError("Invalid email. ");
    } 
    else if (currentPassword.length === 0) 
    {
      updateError("Invalid Password.");
    } 
    else 
    {
      updateshowLoader(true);

      createUser(currentemail, currentPassword)
        .then((result) => {
          updateshowLoader(false);
          if (result.data === "User Created") {
            updateRootemail(currentemail);
            navigation(SPA_PATHS.BLOG_CREATE);
          }
        })
        .catch((_) => {
          updateshowLoader(false);
          updateError("An error occured while signing up");
        });
    }
  };


  return (
    <div className={style.root}>
      <img src={companyLogo} className={style.logo} alt="" />
      <h2 className={style.title}>Sign Up</h2>
      <form className={style.signupForm} onSubmit={onSubmit}>
        <div>
          <label htmlFor="userEmail">Email</label>
          <input
            type="email"
            id="userEmail"
            onChange={(e) => {
              updateError("");
              updateemail(e.target.value);
            }}
            value={email}
          />
        </div>
        <div>
          <label htmlFor="userPassword">Password</label>
          <input
            type="password"
            id="userPassword"
            onChange={(e) => {
              updateError("");
              updatePassword(e.target.value);
            }}
            value={password}
          />
        </div>
        <input type="submit" value="Submit" />
      </form>
      <p className={style.error}>{error}</p>
      {showLoader && <Spinner small fast></Spinner>}
    </div>
  );
}


export default SignUp;


function filterText(text) {
  if (typeof text == "string") {
    text.trim();
  }
  return text;
}
