import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Home/Home";
import React from "react";
import Question from "./Question/Question";
import QuestionContent from "./Question/QuestionContent";
import BlogAdd from "./Blog/BlogAdd/BlogAdd";
import SignUp from "./SignUp/SignUp";
import Login from "./Login/Login";

import About from "./About/About";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Terms from "./Terms/Terms";
import BlogDisplay from "./Blog/BlogDisplay/BlogDisplay";
import BlogView from "./Blog/BlogView/BlogView";
import ContactUs from "./ContactUs/ContactUs";
import BlogAddAccessChecker from "./Blog/BlogAddAccessChecker/BlogAddAccessChecker";
import { SPA_PATHS } from "./SPANavigation";

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path={SPA_PATHS.ROOT} element={<Home></Home>}></Route>
          <Route path={SPA_PATHS.QUESTION_LIST} element={<Question></Question>}></Route>

          <Route path={`${SPA_PATHS.QUESTION_VIEW}/:id`} element={<QuestionContent />} />
          <Route path={SPA_PATHS.BLOG_CREATE} element={<BlogAdd />} caseSensitive />
          <Route path={SPA_PATHS.SIGN_UP} element={<SignUp />}/>
          <Route path={SPA_PATHS.LOG_IN} element={<Login />} />
          <Route path={SPA_PATHS.ABOUT_US} element={<About />}></Route>
          <Route path={SPA_PATHS.PRIVACY_POLICY_PAGE} element={<PrivacyPolicy/>}></Route>
          <Route path={SPA_PATHS.TERMS_PAGE} element={<Terms />}></Route>
          <Route path={SPA_PATHS.CONTACT_US_PAGE} element={<ContactUs />}></Route>
          <Route path={SPA_PATHS.BLOG_LIST} element={<BlogDisplay></BlogDisplay>}></Route>
          <Route path={SPA_PATHS.AUTH_VERIFIER} element={<BlogAddAccessChecker></BlogAddAccessChecker>}></Route>
          <Route path={`${SPA_PATHS.BLOG_VIEW}/:id`} element={<BlogView></BlogView>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
