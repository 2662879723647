import React from "react";
import style from "./HeroSection.module.css";

import mockupImage from "../../assets/images/app-mockup.png";

import playStoreLogo from "../../assets/logos/play_store.png";
import appleStoreLogo from "../../assets/logos/app_store.png";
function HeroSection() {
  return (
    <section className={style.root}>
      <div className={style.back}></div>
      <div className={style.mockupWrapper}>
        <img src={mockupImage} alt="App Mockup" />
      </div>
      <div className={style.heroTextWrapper}>
        <h2 className={style.heroTitle}>
          Get clarification for all your doubts within few minutes !!!
        </h2>
        <p className={style.heroDescription}>
          Here at Que, we offer you the best solutions at the most affordable
          prices. Ask questions from a wide range of categories and get them
          solved in minutes.
        </p>

        <div className={style.linkButtonWrapper}>
          <a href="https://play.google.com/store/apps/details?id=com.acadque.que" className={style.storeLinkButton} target="_blank">
            <img src={playStoreLogo} alt="Download the app in Play Store" />
            <div>
              <p>
                Play Store <br /> <span>Download Now!</span>
              </p>
            </div>
          </a>
          <a href="*" className={style.storeLinkButton}>
            <img
              src={appleStoreLogo}
              alt="Download the app in Apple App Store"
            />
            <div>
              <p>
                App Store <br />
                <span>Releasing Soon</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}
export default HeroSection;
