import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";

export const BlogsHolderContext = createContext();
export const BlogsHolderProvider = ({ children }) => {
  const [blogs, updateBlogs] = useState([]);
  return <BlogsHolderContext.Provider value={[blogs, updateBlogs]}>{children}</BlogsHolderContext.Provider>;
};
export const BlogsData = () => useContext(BlogsHolderContext);
