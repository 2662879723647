import React, { useState, createContext } from "react";
export const Context = createContext();

export const Provider = (props) => {
  const [question, setquestion] = useState("");
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [result, setresult] = useState([]);

  return (
    <Context.Provider value={{ show, setShow, question, setquestion, result, setresult, loading, setLoading }}>
      {props.children}
    </Context.Provider>
  );
};
