import React from "react";
import Footer from "../Home/Footer/Footer";
import MenuBar from "../Home/MenuBar/MenuBar";
import style from "./Terms.module.css";

export default function Terms() {
  return (
    <div>
      <MenuBar />
      <div className={style.termmain}>
        <div className={style.termsection}>
          <div class={style.termhead}>
            <h2 class={style.termheading}>Terms of Use</h2>
          </div>
          <div className={style.termcontent}>
            <p className={style.updatedate}>Last updated: June 12, 2021</p>

            <p>
              Please read these terms and conditions carefully before using Our
              Service.
            </p>

            <h1>AcadQue Use Agreement Parties</h1>
            <p>
              This AcadQue Use Agreement (&ldquo;Agreement&rdquo;) is entered
              into between AcadQue Technologies Private limited. who is the
              creator and the owner of all intellectual and industrial property
              rights of the Que mobile applications, and real or legal person
              user who wants to utilize the contents and services of AcadQue
              mobile applications; and it regulates the terms and conditions
              required to use Que mobile Tutor application and to utilize
              provided services and offered contents.{" "}
              <strong>Definitions</strong> In this agreement refer to:
            </p>
            <ul>
              <li>
                <strong>AcadQue Technologies </strong>: AcadQue Technologies
                Private Limited,
              </li>
              <li>
                <strong>AcadQue Service</strong>: Any application, content,
                service, offered by AcadQue Technologies including the website
                of acadque.com, and AcadQue mobile applications,
              </li>
              <li>
                <strong>User</strong>: Real or legal persons who want to utilize
                AcadQue Service,
              </li>
              <li>
                <strong>Tutor</strong>: Responders who answer questions by
                users,
              </li>
              <li>
                <strong>Account</strong>: User account created with all
                information shared in AcadQue Service,
              </li>
              <li>
                <strong>Content</strong>: All data, texts, files, information,
                usernames, images, graphics, photos, account information, audio
                and video clips, sounds, musical works, original works,
                applications, links and other content and materials, which are
                sent by the Users to the AcadQue Service or shared and / or
                displayed in the AcadQue Service,
              </li>
              <li>
                <strong>AcadQue Technologies Parties</strong>: AcadQue
                Technologies employees, managers, officers or representatives.
              </li>
            </ul>

            <h1>Preamble</h1>
            <ul>
              <li>
                In order to use the AcadQue Service, the terms and conditions of
                this Agreement must be accepted and committed by the User. When
                you access and / or use the AcadQue Service, regardless of how
                you have accessed it, you agree that you are subject to this
                Agreement. If you do not agree to be bound by this Agreement,
                you must not access the AcadQue Service or use the AcadQue
                Service.
              </li>
              <li>
                The Agreement shall come into force when the user accepts the
                agreement, and the User agrees, declares and commits that the
                User is authorized to access and use the AcadQue Service and
                undertakes any liability in access to the services and selection
                and use of the services.
              </li>
              <li>
                Accepting this Agreement, the real person User acknowledges and
                declares that the User is over 18 years of age and has the
                capacity to act as a contractual entity; and the legal person
                User acknowledges and declares that this Agreement is
                acknowledged by authorized persons and is binding for the legal
                person.
              </li>
              <li>
                If the User is under 18 years of age, he accepts that he can
                benefit from the AcadQue Service with the consent and by means
                of his parent.
              </li>
              <li>
                AcadQue is a digital marketplace. AcadQue Service consists of
                access to and use of the AcadQue marketplace.
              </li>
              <li>Users make payments to AcadQue as charges per question.</li>
              <li>
                AcadQue Service is offered to Users through mobile applications
                under the agreement between Tutors and AcadQue to organize and
                plan the solution of test questions.
              </li>
              <li>
                AcadQue Technologies gives the User access to the AcadQue
                Service upon acceptance of this Agreement and access to the
                AcadQue Service is contingent upon compliance with the terms and
                conditions of this Agreement.
              </li>
              <li>
                AcadQue Technologies reserves the right to alter the terms of
                this Agreement at times in its sole discretion (&ldquo;
                <strong>Updated Terms</strong>&ldquo;). Except for the
                circumstances where due to legal or administrative reasons, the
                aforementioned alterations are required to take effect
                immediately, AcadQue Technologies informs the Users, where
                possible, within a reasonable period of time before Updated
                Terms enter into force.
              </li>
              <li>
                User acknowledges that the Updated Terms may be announced to
                Users by being published on the AcadQue Service and that use (or
                another act that is specified reasonable) of the AcadQue Service
                after the effective date of the Updated Terms would mean that
                the Updated Terms are accepted. Therefore, it is the
                responsibility of the User to review this Agreement and all the
                Updated Terms before using the AcadQue service. The Updated
                Terms shall come into force at the time they were published or
                at a later date specified in the Updated Terms and as of that
                date AcadQue Service Updated Terms are valid for the utilization
                by Users. Disputes that arise in the Updated Terms will be
                subject to this Agreement.
              </li>
            </ul>

            <h1>
              Liabilities of Users for Accuracy and Keeping up to Date of the
              Account Information
            </h1>
            <ul>
              <li>
                Liability of all actions made with the Account that is created
                with all kinds of information shared by the User in the AcadQue
                Service are accounted for by the User.
              </li>
              <li>
                AcadQue Technologies prohibits the User from creating an Account
                on behalf of any one other than himself and the User accepts
                that he will not do so. In addition, at the time of registering
                for the AcadQue Service, creating Account and in the following
                times, the User undertakes that the information provided or will
                be provided by the User is true, correct, current and complete
                and that he will update the changes without delay in order for
                the information to remain true and correct.
              </li>
              <li>
                The User is responsible for all transactions that take place on
                his Account or are performed over his Account, and agrees to
                keep his Account and all information including the contact
                information in the Account safe and confidential at all times.
              </li>
              <li>
                In the case that the User does not provide accurate, complete
                and up-to-date Account information, the User may be denied
                access to and the use of the AcadQue Service or AcadQue may
                terminate any agreement (including this Agreement) that has been
                entered into.
              </li>
              <li>
                In case the phone number changes or the telephone line is
                closed, the User undertakes to update the Account information
                without delay, in order for the messages to be sent to the right
                person. In the event that messages sent to an non-updated phone
                number are received by the wrong person, the user shall be
                responsible for the full responsibility.
              </li>
            </ul>

            <h1>Conditions for Wallet Use and Withdrawal</h1>
            <ul>
              <li>
                The user will have, on signing up on the AcadQue app, access to
                the facility of wallet in the AcadQue app.
              </li>
              <li>
                On signing up and using the AcadQue wallet for any or whatsoever
                purpose, the user will have agreed to follow and abide by the
                AcadQue app&rsquo;s wallet use conditions.
              </li>
              <li>
                The user is responsible to add money to the wallet as and when
                required.
              </li>
              <li>
                AcadQue does not take responsibility for any delay in services
                due to lack of required funds in the wallet.
              </li>
              <li>
                The funds added in the wallet by the user will not be refundable
                and can only be used for services provided by AcadQue.
              </li>
              <li>
                The user is required to first report to the AcadQue team in case
                of any grievances. The AcadQue team reserves the right to
                resolve complaints.
              </li>
              <li>
                The user can not hold AcadQue responsible for any grievances
                that the user fails to notify the AcadQue team of before
                considering any other dispute resolution method.
              </li>
              <li>
                On using the AcadQue services, the user agrees to wait for the
                period of 30 days, from the date of filing the complaint, to
                allow the AcadQue to address the user complaint.
              </li>
            </ul>

            <h1>Rules for Account Use</h1>
            <ul>
              <li>
                The User may have only one Account, unless permitted otherwise
                by AcadQue in writing.
              </li>
              <li>
                The services apply solely to the User&rsquo;s own use, unless
                permitted by AcadQue in writing.
              </li>
              <li>
                The User agrees that he will not request, collect and use the
                login information of other Users.
              </li>
              <li>
                The User may not modify, adapt or copy any software or content
                related to the AcadQue Service. The User may not create content
                and modify existing content or behave in a manner that gives the
                false impression that any other website or application is
                associated with the AcadQue Service or AcadQue Technologies.
              </li>
              <li>
                The User cannot block or interrupt the AcadQue Service, its
                servers, or networks connected to the AcadQue Service, including
                transferring worms, viruses, spyware, malware, or any other
                damaging or blocking code. The User cannot add content or codes
                that change or prevent the way the AcadQue pages are displayed
                or viewed in the browsers or devices of the Users.
              </li>
              <li>
                The User cannot create an account in the AcadQue Service with
                unauthorized methods, including but not limited to, using
                automatic device, code, bot, crawler, bug, or scraper.
              </li>
              <li>
                The User undertakes not to restrict the use of the AcadQue
                Service by another User and not to encourage the breach of this
                Agreement or other AcadQue terms. The User agrees to immediately
                notify AcadQue if he finds out that a third party is breaching
                the terms of this Agreement or other AcadQue terms. The User
                shall be deemed to have participated in the violation, if he
                enables violation by such third parties or does not inform
                AcadQue after being informed of the violation.
              </li>
              <li>
                Tutors offering solutions to questions by Users may be teachers,
                university students or any person who meets the evaluation
                criteria of AcadQue.
              </li>
              <li>
                AcadQue, while targeting a service that offers Users a
                high-quality solution in the shortest possible time, in standard
                questions, does not guarantee about the duration the questions
                sent by Users will be solved.
              </li>
              <li>
                If the answer to the question is incorrect, in this case,
                student may demand the restitution of the deducted question
                right from the Account for the said question and the correct
                solution of the said question is transmitted to the User free of
                charge. In the case that the User thinks a question he
                transmitted is solved incorrectly, for notification and refund
                requests, he may report in the application that the solution is
                wrong or send an e-mail to support@acadque.com
              </li>
              <li>
                It is forbidden for the User to share in any way, his own
                contact information (such as telephone number, e-mail address,
                social network user name) through AcadQue Service applications
                and/or website or by other means. In addition, the User
                undertakes that he will not ask for the contact information of
                other Users either through AcadQue Service or by any other
                means, or will not try to obtain it in any other way.
              </li>
              <li>
                If the User changes his phone number and phone together, he can
                move his old account to the new account through the message that
                will be sent to the e-mail address registered to the old
                Account.
              </li>
              <li>
                The Guidance service offered in certain subscription packages
                also works with the marketplace model, and AcadQue accepts no
                responsibility for the suggestions and contents offered.
              </li>
            </ul>

            <h1>Content-Related Liabilities of the User</h1>
            <ul>
              <li>
                User is solely responsible for the contents sent to the AcadQue
                Service by the User.
              </li>
              <li>
                The User undertakes not to upload through the AcadQue Service
                any photographs or other Content that are irrelevant,
                discriminatory, illegal, derogatory, pornographic, obscene or
                containing violence, nudity, partial nudity.
              </li>
              <li>
                User undertakes not to; insult, tyrannise or act in a disruptive
                manner towards the Tutor, the institution, the organization and
                the individuals; abuse, harass, threaten, imitate or intimidate
                these persons; disclose any confidential information (including
                but not limited to credit card information, social security or
                other national identification numbers, non-public telephone
                numbers, or non-public email addresses) belonging to himself or
                someone else through the AcadQue Service.
              </li>
              <li>
                The User accepts that if he submits a non-question content, he
                will not be responded with a solution due to the absence of a
                question, and the service fee will be deducted from his Account
                due to occupying the time of the Tutor.
              </li>
              <li>
                The User agrees not to use the AcadQue Service for any illegal
                or unauthorized purposes. The User agrees to abide by all
                applicable laws and regulations, including but not limited to
                copyright laws, when using the AcadQue Service and when sharing
                Content.
              </li>
              <li>
                Contents in the AcadQue Service cannot be scanned, withdrawn,
                cached, or otherwise accessed via automatic methods (except by
                the standard search engine protocols or technologies used by a
                search engine with the explicit permission of AcadQue
                Technologies).
              </li>
              <li>
                AcadQue does not take ownership of the Content shared by Users
                on or through AcadQue Service. Instead, the User assigns a
                non-exclusive, paid, royalty-free, transferable, sublicensable
                international license to AcadQue Technologies for the use of the
                Content he shared with or over the AcadQue Service which shall
                be bound by Privacy Policy of AcadQue Service including, but not
                limited to, Privacy Policy chapter 3 (&ldquo;Sharing Your
                Information&rdquo;), chapter 4 (&ldquo;How We Store Your
                Information&rdquo;), and chapter 5 (&ldquo;Your Selections About
                Your Information&rdquo;).
              </li>
              <li>
                In this case, the User declares and acknowledges that: (I) he is
                the owner of the Content he shared on or through AcadQue Service
                or has the right to vest the rights and licenses specified in
                this agreement; (ii) he will not commit or violate any third
                parties rights to share or use Content on or through AcadQue
                Service including but not limited to privacy rights, personality
                rights, copyright, trademark and/or other intellectual property
                rights; (iii) he accepts to pay any kinds of royalties, fees, or
                any other charges that are required to be paid because of the
                Content he shared on or through AcadQue Service; and (iv) he has
                the legitimate rights and authority to accept this agreement in
                the judicial district where he is located.
              </li>
              <li>
                When the User shares a Content or information, he will have
                granted AcadQue Technologies and the Tutors who will solve the
                question with access and use permission for their use in other
                future services of the application.
              </li>
              <li>
                The accuracy, classification, quality and recommended difficulty
                level of the content seen in the Question Bank, is in the User
                and Tutor&rsquo;s responsibility and AcadQue assumes no
                responsibility.
              </li>
              <li>
                Part of the AcadQue Service may be supported by advertising
                revenue; and advertisements and promotions may be presented in
                AcadQue Service. The User hereby agrees that AcadQue
                Technologies may place such advertisements and promotions in the
                AcadQue Service or in, next to or together with the Contents.
                The form, style and scope of such advertisements and promotions
                may be changed without notice.
              </li>
              <li>
                The User acknowledges that AcadQue is not responsible for the
                Content Shared in the AcadQue Service. AcadQue shall not be
                obligated to scan, monitor, edit or remove any Content in
                advance. In the event that the contents violate this Agreement,
                the legal liability of the Content in question is the
                responsibility of the User.
              </li>
              <li>
                Unless otherwise stated in the Privacy Policy of the AcadQue
                Service, no Content between the User and AcadQue shall be
                confidential and proprietary. AcadQue Technologies will not be
                liable in any way for the use or disclosure of the Content that
                the User shares, and AcadQue will not have any responsibility
                for the use, sharing or non-sharing of these. The User
                acknowledges and approves that the relationship with AcadQue is
                not confidential, trust-based, or otherwise special, and that
                any decision to submit any Content does not place AcadQue in a
                position different from third parties or people in the general
                public, including being included in the Content. No part of the
                Content will be subject to any security obligations by AcadQue
                and AcadQue shall not be liable for any use or disclosure of the
                Content.
              </li>
            </ul>

            <h1>AcadQue Contents and Copyrights</h1>
            <ul>
              <li>
                Any claims and license on the AcadQue Service or software
                pertaining to it belongs to AcadQue, providing the User entry to
                AcadQue Service does not mean in any way that license is issued.
                The absence of a provision under this Agreement or any other
                agreement between the User and AcadQue Technologies that could
                mean a license is issued to the User has been recognized by the
                parties.
              </li>
              <li>
                Content belonging to AcadQue Technologies or licensed by AcadQue
                Technologies can be found in AcadQue Service (&ldquo;
                <strong>AcadQue Technologies Contents</strong>&ldquo;). AcadQue
                Technologies Content is protected by copyright, trademark,
                patent, trade secret and other laws, and between the User and
                AcadQue Technologies all rights of AcadQue Technologies Content
                and AcadQue Service belong to AcadQue Technologies. The User
                shall not remove, alter or hide any copyright, trade mark,
                service mark or other property rights incorporated or
                accompanied into AcadQue Technologies Contents and shall not
                reduplicate, alter, adapt, create derivatives of, play/replay,
                view, broadcast, distribute, transfer, sell, allocate the
                license of and abuse in any other way the AcadQue Technologies
                Contents.
              </li>
              <li>
                The AcadQue name and logo are trademarks of AcadQue and may not
                be copied, reproduced or used in whole or in part without prior
                written permission from AcadQue. In addition, all page titles,
                custom graphics, button icons, and codes are service marks,
                trademarks, and / or business forms of AcadQue and may not be
                copied, reproduced or used in whole or in part without prior
                written permission from AcadQue.
              </li>
              <li>
                The User may not modify, decompile, create derivatives of, or
                otherwise attempt to extract source code of the AcadQue software
                if it is not expressly permitted under an open source license or
                not permitted with an express written permission of AcadQue
                parties.
              </li>
              <li>
                If the User downloads and uses, AcadQue software such as
                stand-alone software product, application or browser add-on
                downloads or, he agrees to download and install from time to
                time the updates, upgrades or additional features by the
                software to improve, update or further develop the software.
              </li>
              <li>
                AcadQue shall not accept or take into account any content,
                information, idea, suggestion or other material other than the
                contents, information, ideas, suggestions or other materials
                requested in particular and to which some special terms,
                conditions and requirements may apply. The reason for this is to
                prevent any misunderstandings in the case where the ideas are
                similar to the ideas developed or under development
                independently by AcadQue Technologies. Thus, AcadQue does not
                accept unsolicited materials or ideas and does not assume any
                responsibility for the materials or ideas so transferred. In the
                case that the User, sends content, information, ideas,
                suggestions or other materials to AcadQue Technologies in spite
                of this principle, he accepts that AcadQue is free to use this
                type of content, information, ideas, suggestions or other
                materials, for any purpose, including but not limited to,
                developing and marketing products and services without any
                obligation and payment to User.
              </li>
            </ul>

            <h1>Fees</h1>
            <ul>
              <li>
                The Tutor is responsible for all data charges arising from the
                use of the AcadQue Service. The use of AcadQue Services by
                persons other than the User is prohibited and any legal,
                financial or criminal responsibility arising from the use of
                these by others by any means shall be borne by the User.
              </li>
              <li>
                AcadQue, under its sole discretion, reserves the right to
                install, remove and / or modify costs for any or all aspects of
                the AcadQue Service or other services offered by AcadQue.
                AcadQue can make changes to packages and / or subscription
                prices in any time period. Previous package and / or
                subscription purchases are not affected positively or negatively
                by these changes. In addition, it has been acknowledged and
                agreed by the parties that the costs applied in specific
                geographical areas and courses may vary significantly during
                high demand periods of the AcadQue Service. Even if they are
                within the User&rsquo;s knowledge, AcadQue makes reasonable
                efforts to inform the User of any costs that may be incurred
                under his Account, or amounts in connection with them, for which
                the User will be liable. AcadQue occasionally provides
                promotional offers and discounts and opportunities which may
                result in different costs for the same or similar AcadQue
                Services to certain Users and as long as it is offered to the
                User, the User agrees to such promotional offers and discounts
                without any effect on use of the services or costs applied to
                the User.
              </li>
              <li>
                The User agrees that promotions (i) should be used for the
                purpose and purpose and in a legal manner; (ii) may not be
                reproduced, sold or otherwise transferred or made publicly
                available, unless expressly permitted by AcadQue Technologies;
                (iii) may be disabled by Zero Five at any time for any reason
                without obligation to AcadQue; (iv) may be used in accordance
                with certain conditions established by AcadQue Technologies for
                a particular Promotional Code; (v) do not apply to cash; and
                (vi) may be terminated before use. AcadQue reserves the right to
                restrict or discount credits, other features or benefits if it
                detects error, fraud, or illegal circumstances by the User or by
                another User in the promotion use or depreciation; or believes
                and determines violation of the promotion conditions and these
                conditions.
              </li>
              <li>
                Users who are new members of the AcadQue Service may be
                periodically granted free question rights. AcadQue Technologies
                has no obligation to reflect this right to existing Users.
                Periodically, free question rights may be accorded to the
                AcadQue Service User as a result of User inviting their friends
                and each friend subscribing to the AcadQue Service. The
                promotions that are earned in return of inviting friends are
                only valid for invitations via SMS in AcadQue Service and are
                limited to 50 questions. Periodic campaigns cannot be used with
                packages purchased in the past.
              </li>
              <li>
                Obtaining the data network access that is required to use the
                Services is under the responsibility of the User. Mobile network
                data and messaging rates and fees may be applied for the use or
                access of wireless-enabled devices to AcadQue Services.
                Acquisition and updating of hardware or devices compatible with
                access to and use of the AcadQue Services and additional updates
                are under the responsibility of the User. AcadQue Technologies
                does not guarantee that AcadQue services, or any portion of it
                will work on any hardware or device. In addition, AcadQue
                Services may be subject to malfunctions and delays that may
                arise from the use of internet or electronic communications.
                AcadQue Technologies will not be liable for delays arising from
                such reasons.
              </li>
              <li>
                In the event of disruptions to access to the AcadQue Service due
                to internet provider or other technical reasons beyond the
                control of AcadQue Technologies, AcadQue Technologies aims to
                solve these shortcomings as soon as possible, but the User
                accepts that AcadQue Technologies will not have any
                responsibility for the disruption of the access to AcadQue
                Services for these reasons.
              </li>
            </ul>

            <h1>Termination of the Service and Cancellation</h1>
            <ul>
              <li>
                AcadQue Technologies reserves the right to terminate or to
                change access to the AcadQue Service without notice. In the
                event of the termination of the AcadQue Service, it is accepted
                and admitted by the User that the responsibility of the AcadQue
                is limited to the amount that the User has not used in his
                wallet, and that AcadQue has no other legal, financial or
                criminal liability.
              </li>
              <li>
                User, if he wishes to terminate the AcadQue Service Account, may
                do so by logging in the AcadQue service and using the
                &ldquo;Problem Report&rdquo; option in the settings screen or
                sending an e-mail describing the demand to support@acadque.com
                address. Termination of access to the AcadQue Service means
                closure of access over the User&rsquo;s Account to pictures,
                insights, questions, answers transmitted to questions and other
                data, but this information and the data may continue to remain
                in the AcadQue Service, and to appear or be used for different
                services in the future in a way that does not reveal the
                identity of the User.
              </li>
              <li>
                Any violation of one or more than one provision of the
                Agreement, in AcadQue Technologies&rsquo;s sole discretion, may
                result in the closure of the User Account. The User accepts
                that, the Content he shared in AcadQue Service is not and cannot
                be under the responsibility of AcadQue Technologies and the
                risks that might be faced when using AcadQue Service will be
                solely under the responsibility of the User. AcadQue
                Technologies may terminate the submission AcadQue Service to the
                User entirely in the case that the User violates the essence or
                the text of this Agreement in any way, or subjects AcadQue
                Technologies to a risk or any other possible legal sanction.
              </li>
              <li>
                Once the account has been closed, the validity of all rights
                granted in this Agreement shall cease immediately.
              </li>
              <li>
                AcadQue Technologies reserves the right to refuse any person to
                access the AcadQue Service.
              </li>
            </ul>

            <h1>Breach Of The Agreement</h1>
            <ul>
              <li>
                The User accepts that in the case that AcadQue in its sole
                discretion determines any violation of one or more than one
                provision of the Agreement by the User, it may remove, revise,
                block and/or track the Accounts Content or Contents are located
                in, and that AcadQue has no obligation as such. In the event
                that AcadQue, and/or third parties suffer damage as the result
                of the User&rsquo;s violation, the User agrees and undertakes
                that the civil and criminal responsibility is on himself, and
                that he will indemnify the damage.
              </li>
            </ul>

            <h1>Responsibility</h1>
            <ul>
              <li>
                AcadQue does not guarantee the eligibility, accuracy or
                competence of the Tutors. It is only the User&rsquo;s
                responsibility to determine that the Tutors will meet the
                User&rsquo;s needs and expectations. AcadQue will not be
                involved in disputes between Users and Trainers. By using the
                AcadQue Service, the User accepts that the risk and discretion
                of the potential insecurity, attack, harm to the minors, or
                cases otherwise organized or planned by the Tutors using the
                services, belong to the User. AcadQue has no liability
                concerning the situations related to or arising from the
                relations or activity between Tutors or Users.
              </li>
              <li>
                Online or offline interactions of the User with other Users of
                AcadQue Service are solely the responsibility of the User. The
                User acknowledges that AcadQue Technologies is not responsible
                or liable for Users&rsquo; conduct. AcadQue Technologies
                reserves the right to monitor or interfere with disputes between
                Users, but has no obligation to do so. When submitting Content
                or any other personal or other information, or interacting with
                others, the User must act discreetly and attentively.
              </li>
              <li>
                Links to third party websites or features can be given in the
                communications received from the AcadQue Service. Images or
                comments from the AcadQue Service may also contain links to
                third-party websites or features. Functions in AcadQue Service
                may allow interactions between AcadQue Service and third party
                websites or features. This includes applications that link the
                AcadQue Service and the User Account in AcadQue Service to
                third-party websites or features. For example, a feature in the
                AcadQue Service may allow the User to share Content from the
                AcadQue Service or to share the Content with a third party and
                these may be publicly shared on the service or application of
                that third party. In order to use this function, Users are
                usually required to log in to the User&rsquo;s Account from the
                third-party service, and the risks that may be encountered in
                doing so are entirely in the responsibility of the User. AcadQue
                Technologies has no control over these third-party internet
                services or content. The User expressly acknowledges that
                AcadQue Technologies is not responsible or liable for any such
                third party services or features. The communication and business
                transactions between third parties through the AcadQue Service
                are entirely between the User and the third party. The User may,
                in his sole discretion and taking full responsibility for the
                risks he may face, choose to use the applications (each referred
                to as &ldquo;Application&rdquo;) that connect the AcadQue
                Service or the Account in the AcadQue Service with the third
                party services, and the Application in question may interact and
                connect with the User Account in the AcadQue Service, or collect
                and/or receive Account information. Using such Applications, the
                User accepts the following: (I) if an Application is used to
                share information, the information related to the Account in
                AcadQue Service may be shared, (ii) use of an Application may
                cause the personally identifying information to be publicly
                disclosed and/or be associated with the User, even if AcadQue
                Technologies does not provide this information and (iii) the use
                of the application depends solely on the User&rsquo;s preference
                and the risks that may arise are in the responsibility of the
                User and the User may need to indemnify AcadQue Technologies
                against losses that will be born of activities relating to the
                Application.
              </li>
              <li>
                Although the purpose of AcadQue is to ensure that AcadQue
                Service is available as much as possible, the AcadQue Service
                may be interrupted, due to reasons including, but not limited
                to, scheduled maintenance or upgrade, emergency repairs or the
                breakdown of telecommunications connections and / or equipment.
                In addition, AcadQue reserves the right to remove any content in
                the AcadQue Service for any reason without prior notice. The
                contents removed from AcadQue Service may continue to be stored
                by AcadQue for purposes of, including but not limited to,
                adhering to various legal obligations. Consequently, AcadQue
                \recommends Users to back up their Contents. In other words,
                AcadQue Service is not a backup service and it may not be
                expected to back up or store Content. In the event of
                modification, cessation and termination of AcadQue Services or
                loss of Content, AcadQue Technologies has no obligation to the
                User. The User also agrees that the Internet may be subject to
                security breaches and sending Contents or other information may
                not be secure.
              </li>
            </ul>

            <h1>Warranty Disclaimer</h1>
            <ul>
              <li>
                The AcadQue Service, including but not limited to the AcadQue
                Content, is offered &ldquo;as is&rdquo; and &ldquo;with all its
                errors&rdquo;. To the fullest extent permitted by law, none of
                the AcadQue Parties provide any express or implied declaration
                or warranty or support for the security regarding information
                transfer to (a) AcadQue Service, (b) AcadQue Contents, (c) User
                Contents, or (d) to AcadQue or through AcadQue Service. In
                addition, the AcadQue Parties hereby disclaim any and all
                express or implied warranties, including, but not limited to;
                merchantability; fitness for a particular purpose;
                non-infringement; warranty of title; special, commercial,
                non-intrusive use; system integration; and not containing
                computer virus.
              </li>
              <li>
                AcadQue Parties do not declare or warrant that the AcadQue
                Service will operate without error or fail, or that defects will
                be corrected or that the AcadQue Service or server offering the
                AcadQue Service does not contain harmful components including
                but not limited to viruses. AcadQue Technologies Parties do not
                declare or warrant that the information contained in the AcadQue
                Service (including instructions) is correct, complete or useful.
                The user agrees that he is responsible for the risks arising
                from using the AcadQue Service. AcadQue Technologies Parties do
                not guarantee that the use of the AcadQue Service is legal in
                any jurisdiction, and AcadQue Technologies Parties specifically
                disclaim any such warranties.
              </li>
              <li>
                The User hereby declares and warrants that by accessing the
                AcadQue Service, any action he takes through the AcadQue Service
                is lawful in all jurisdictions where he accesses to the AcadQue
                Service or uses the AcadQue Service.
              </li>
              <li>
                AcadQue Parties do not support the Content and specifically
                disclaim any liability or obligation for any loss, damage
                (actual, consequential, criminal or otherwise), indemnity,
                liability or any other reason arising from the Content or
                Content.
              </li>
            </ul>

            <h1>Limitation of Liability; Disclaimer</h1>
            <ul>
              <li>
                AcadQue shall not either directly or indirectly have any
                obligations to the User for any loss or damage arising from (a)
                AcadQue Service; (b) AcadQue Contents; (c) User Contents; (d)
                use, non-use of AcadQue Service or performance of AcadQue
                Service; (e) any litigation in connection with any investigation
                conducted by AcadQue Technologies Parties or security units in
                relation to use of AcadQue Service by User or another party; (f)
                any litigation in connection with copyright or other
                intellectual property right holders; (g) any error or fault in
                operation of AcadQue Service or (h) including but not limited
                to, all kinds of damages arising from any security breach or any
                virus, worm, tampering, fraud, error, omission, interruption,
                failure, operation or transfer delays, computer line or network
                failures or all other technical errors or malfunction resulting
                in, including but not limited to, profit loss, loss of goodwill,
                data loss, work stoppage, error in results or computer failure
                or malfunction in the computer, mobile device or other equipment
                or technology of any user.
              </li>
              <li>
                In the event of any damage, loss or injury arising from acts or
                omissions of AcadQue Technologies, User agrees that damage
                suffered, if any, is not reparable or is not sufficient in order
                to be entitled a court order obstructing to utilize any web
                site, application, service, property, product or other content
                possessed or controlled by AcadQue Technologies Parties and he
                shall not be entitled to obstruct or restrict development,
                production, distribution, advertising, exhibition or utilization
                of any web site, application, property, product, service or
                other content possessed or controlled by AcadQue Technologies
                Parties.
              </li>
              <li>
                AcadQue Technologies is not responsible for third party
                activities, content, information, or data. User agrees to acquit
                AcadQue Technologies, its directors, employees and agents of any
                demand and harm arising from or associated with the subject of a
                court case against known or unknown, any third party.
              </li>
            </ul>

            <h1>Indemnification</h1>
            <ul>
              <li>
                User agrees to indemnify AcadQue Technologies against any
                compensation and demands AcadQue Technologies will be faced with
                due to or related to (I) access to User Content or Service use;
                (ii) violation or alleged violation of this Agreement; (iii)
                violation of the rights of any third party, including but not
                limited to, intellectual property rights, personality, privacy,
                or proprietary rights; (iv) violation of any law, rule,
                regulation, legislation, guidelines, decision, or instruction of
                any type of government and government like institution including
                but not limited to regulator, administrative and legislative
                authority of any type; (v) any behaviour of the User, including
                but not limited to, fraudulent misrepresentation. In the face of
                such a request, the User agrees to defend AcadQue Technologies
                (upon request of AcadQue Technologies) and hold it harmless, and
                to indemnify all kinds of compensations, obligations, damages,
                losses and expenses likely to occur thereon including but not
                limited to any attorney fees and costs likely to occur in
                connection with these or occurred in relation to any of the
                abovementioned (including the actions User has directly
                performed in AcadQue Service or activities performed on his
                behalf).
              </li>
              <li>
                The user undertakes to cooperate in the defense of all claims as
                requested by AcadQue Technologies. AcadQue Technologies reserves
                the right to execute exclusive defense and control of any issue
                subject to User&rsquo;s indemnification and User accepts that he
                shall not finalize any indemnification request without obtaining
                prior written consent from AcadQue Technologies.
              </li>
            </ul>

            <h1>Time Limitation in Right Claims</h1>
            <ul>
              <li>
                The User acknowledges that any claim arising from the
                relationship between the AcadQue Technologies must be made
                within one year of the date on which it arises, otherwise the
                claim cannot be made legally.
              </li>
            </ul>

            <h1>Governing Law and Venue</h1>
            <ul>
              <li>
                It has been declared and accepted by the parties that this Use
                Agreement, without regard to principles of international law,
                shall be ruled, interpreted and concluded in accordance with the
                laws of the Indian Constitution; all manner of records of
                AcadQue Technologies shall constitute direct evidence with
                regard to; Indian Courts and Enforcement Offices shall have
                jurisdiction in any case of dispute and controversy.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
