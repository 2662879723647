import React from "react";
import style from "./FeatureCard.module.css";
function FeatureCard({ inverse, image, title, description, customKey }) {
  return (
    <article
      className={`${style.root} ${inverse && style.inverse}`}
      key={customKey}>
      {image && <img src={image} alt={title} className={style.featureImage} />}
      <div className={style.over}></div>
      <div className={style.textWrapper}>
        <h2 className={style.title}> {title}</h2>
        <p className={style.description}>{description}</p>
      </div>
    </article>
  );
}
export default FeatureCard;
